<template>
  <v-container grid-list-xs class="custom-container-large">
    <v-card class="py-4">
      <v-card-text>

        <v-row align="center" no-gutters>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-avatar class="avatar text-uppercase list-avatar" size="100">
                  <v-img
                    v-if="userData.avatar_url"
                    :src="userData.avatar_url"
                    :alt="firstLetter(userData.name || '')"
                  ></v-img>
                  <span
                    v-else
                    class="secondary--text display-3 text-uppercase font-weight-medium"
                  >{{ firstLetter(userData.name || "") }}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="text-h5 font-weight-medium d-inline-block text-truncate"
                  >
                    <v-edit-dialog
                      @save="saveName"
                      @open="newName = (userData.name || null)"
                    >
                      {{ userData.name || null }} <v-icon class="mt-n1 pl-1">mdi-pencil</v-icon>
                      <template v-slot:input>
                        <v-text-field
                          v-model="newName"
                          :rules="nameFieldRules"
                          :label="$t('edit_name')"
                          single-line
                        ></v-text-field>
                      </template>

                    </v-edit-dialog>
                  </v-list-item-title>

                  <v-list-item-title
                    class="text-h6 font-weight-regular pb-2 d-inline-block text-truncate"
                  >{{ userData.email }}</v-list-item-title>

                  <v-list-item-subtitle
                    class="subtitle-2 d-inline-block text-truncate"
                  >
                    <a
                      @click="openChangePasswdModal"
                      class="text-decoration-underline"
                      color="primary"
                    >{{ $t('change_password') }}</a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col class="text-left text-sm-right mt-2 mb-5 px-5">
            <v-btn
              @click="googleLogin"
              color="primary"
              ripple
            >{{ $t('attach_google_account') }}</v-btn>
          </v-col>

        </v-row>

        <v-divider></v-divider>

        <v-row no-gutters>
          <v-container fluid>
            <v-card flat>

              <v-card-title>
                {{ $t('network_shared_volumes') }}
              </v-card-title>

              <v-card-subtitle  class="font-weight-medium">
                <span class="font-weight-bold">{{ $t('user_name') }}:</span> {{ userData.email ? userData.email.replace('@', '_') : null }}
              </v-card-subtitle>

              <v-data-table
                :headers="headers"
                :items="userNetworkShares"
                :loading="loading"
                class="share-table"
                hide-default-footer
              >
                <template
                  v-slot:item.tenant_ids="{ item }"
                >
                  {{ getTenantTitle(item.tenant_ids) }}
                </template>

                <template
                  v-slot:item.synced="{ item }"
                >
                  <v-icon
                    :color="item.synced ? 'success' : 'error'"
                    v-text="'mdi-circle'"
                  ></v-icon>
                </template>
              </v-data-table>

              <v-card-actions>
                <v-btn
                  @click="openSyncPasswdModal"
                  :disabled="userNetworkShares.length < 1"
                  color="primary"
                  outlined
                  ripple
                >{{ $t('sync_credentials') }}</v-btn>
              </v-card-actions>

            </v-card>
          </v-container>
        </v-row>

        <div v-if="show">
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-container fluid>
              <v-card flat>
                <v-card-title>
                  {{ $t('theme') }}
                </v-card-title>
                <v-select
                  item-value="theme_id"
                  item-text="theme_title"
                  @change="writeDefaultTheme"
                  :label="$t('default')"
                  v-model="themeSelected"
                  :items="themeList"
                  outlined
                ></v-select>
              </v-card>
            </v-container>
          </v-row>
        </div>

      </v-card-text>
    </v-card>

    <DefaultModal
      @submit="savePasswd"
      :title="$t('change_password')"
      ref="changePasswdModal"
    >
      <template v-slot:content>
        <v-text-field
          :label="$t('new_password')"
          v-model="newPasswd"
          :rules="passwdRules"
          type="password"
          prepend-inner-icon="mdi-lock-outline"
          required
          outlined
        ></v-text-field>

        <v-text-field
          :label="$t('repeat_password')"
          v-model="repeatPasswd"
          :rules="confirmPasswdRules"
          type="password"
          prepend-inner-icon="mdi-lock-outline"
          required
          outlined
        ></v-text-field>
      </template>
    </DefaultModal>

    <DefaultModal
      @submit="syncNetworkSharesPassword"
      :title="$t('sync_credentials')"
      submit-button-text="sync"
      ref="syncPasswdModal"
    >
      <template v-slot:content>
        <v-text-field
          :label="$t('enter_current_password')"
          v-model="syncPasswd"
          :rules="passwdRules"
          type="password"
          prepend-inner-icon="mdi-lock-outline"
          required
          outlined
        ></v-text-field>
      </template>
    </DefaultModal>
  </v-container>

</template>

<style scoped>
  .avatar {
    background-color: var(--v-primary-base) !important;
  }

  .list-avatar {
    justify-content: center;
  }

  .share-table {
    max-height: 500px;
    overflow-y: auto;
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'

import router from '@/router'
import DefaultModal from '@/components/DefaultModal.vue'

import AppActions from '@/store/app/actions-types'
import AuthActions from '@/store/core/auth/actions-types'
import UserActions from '@/store/core/user/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'
import ThemeActions from '@/store/core/theme/actions-types'
import NetworkShareActions from '@/store/content/network_share/actions-types'

export default {
  name: "UserProfile",

  components: {
    DefaultModal,
  },

  data() {
    return {
      show: false,
      themeSelected: null,
      loading: false,
      themeList: [],
      tenants: [],
      newName: null,
      nameFieldRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v && v.length > 3) || this.$t('form_rules.min_length', {name: this.$t('name'), min: "4"}),
      ],
      newPasswd: null,
      repeatPasswd: null,
      syncPasswd: null,
      passwdRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v && v.length > 3) || this.$t('form_rules.min_length', {name: this.$t('password'), min: "4"}),
      ],
      confirmPasswdRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v === this.newPasswd) || this.$t('form_rules.confirm_password')
      ],
      headers: [
        {text: 'Tenant', value: 'tenant_ids'},
        {text: 'Name', value: 'title'},
        {text: 'Server', value: 'server'},
        {text: 'Permission', value: 'permission'},
        {text: 'Synced', value: 'synced', align: 'center'},
      ],
    }
  },

  mounted() {
    this.fetchTenants()
    this.fetchUserNetworkShares()
    this.fetchThemes()
  },

  computed: mapState({
    authenticated: state => state.core.auth.authenticated,
    userData: state => state.core.auth.userData,
    userNetworkShares: state => state.content.network_share.userNetworkShares
  }),

  methods: {
    ...mapActions('core/auth', [
      AuthActions.DO_LOGOUT,
      AuthActions.DO_LOGIN,
      AuthActions.RENEW_TOKEN,
    ]),

    ...mapActions('core/user', [
      UserActions.UPDATE_USER,
      UserActions.ATTACH_GOOGLE_ACCOUNT
    ]),

    ...mapActions('core/theme', [
    ThemeActions.GET_USER_THEMES,
    ThemeActions.GET_USER_SELECTED_THEME
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE
    ]),

    ...mapActions('content/network_share', [
      NetworkShareActions.GET_USER_NETWORK_SHARES,
      NetworkShareActions.CONFIGURE_SHARE_USER,
    ]),

    logout() {
      this[AuthActions.DO_LOGOUT]()
        .then(() => {
          router.push('/login')
        })
    },

    fetchUserNetworkShares() {
      this.loading = true

      this[NetworkShareActions.GET_USER_NETWORK_SHARES]()
        .then(() => {
          this.loading = false
        })
    },

    fetchTenants() {
      this[TenantActions.GET_ALL_TENANTS]()
        .then((tenants) => {
          this.tenants = [...tenants.items]
        })
    },

    fetchThemes() {
      this[ThemeActions.GET_USER_THEMES]()
        .then((themes) => {
          this.themeList = themes
          var themeId = localStorage.getItem("theme_id_for_user_id_" + this.userData.users_id);
          this.themeList.map((theme) => {
            if (theme.theme_id == themeId) {
              this.themeSelected = theme
            }
          })
          if (this.themeList.length > 1) {
            this.show = true
          } else {
            this.show = false
          }
        })
    },

    writeDefaultTheme(themeId) {
      localStorage.setItem("theme_id_for_user_id_" + this.userData.users_id, themeId);
      this.themeList.map((theme) => {
        if (theme.theme_id == themeId) {
          this.themeSelected = theme
          this.$vuetify.theme.themes.light = JSON.parse(theme.theme_config)
        }
      })
    },

    getTenantTitle(tenantIds) {
      let titles = []

      tenantIds.forEach((id) => {
        const tenant = this.tenants.find(f => f.tenant_id == id)

        if (tenant) {
          titles.push(tenant.title)
        }
      })

      return titles.join(', ')
    },

    firstLetter: (name) => {
      return name.charAt(0)
    },

    openChangePasswdModal() {
      this.$refs.changePasswdModal.openModal()
    },

    openSyncPasswdModal() {
      this.$refs.syncPasswdModal.openModal()
    },

    saveName() {
      const updateData = {usersId: this.userData.users_id, name: this.newName}

      this[UserActions.UPDATE_USER](updateData)
        .then(() => {
          this[AuthActions.RENEW_TOKEN]()
        })
    },

    savePasswd() {
      const updateData = {passwd: this.repeatPasswd, usersId: this.userData.users_id}

      const credentials = {username: this.userData.email, password: this.newPasswd};

      this[UserActions.UPDATE_USER](updateData)
        .then(() => {
          this[NetworkShareActions.CONFIGURE_SHARE_USER]({usersId: this.userData.users_id, data: {password: this.newPasswd}})
          .then(() => {
            this.fetchUserNetworkShares()

            this[AuthActions.DO_LOGIN](credentials)
            .then(() => {
              this.$refs.changePasswdModal.closeModal()
            })
          })
        })
    },

    syncNetworkSharesPassword() {
      this[NetworkShareActions.CONFIGURE_SHARE_USER]({usersId: this.userData.users_id, data: {password: this.syncPasswd}})
        .then(() => {
          this.fetchUserNetworkShares()
        })
        .finally(() => {
          this.$refs.syncPasswdModal.closeModal()
        })
    },

    googleLogin() {
      if (!this.$google.apiInitied) {
        this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Google is not initied'))
        return
      }

      this.$google.login()
        .then(() => {
          this.$google.api.client.drive.about.get({'fields': 'user'})
            .then((response) => {
              let email = response.result.user.emailAddress
              let usersId = this.userData.users_id

              this[UserActions.ATTACH_GOOGLE_ACCOUNT]({email, usersId})
                .then(() => {
                  this[AuthActions.RENEW_TOKEN]()
                    .then(() => {
                      this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on attach Google Account'))
                    })
                })
            })
            .catch((error) => {
              console.log(error)
              this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to retrieve Drive'))
            })
        })
        .catch((error) => {
          console.log(error)
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Unable to login on Google'))
        })
    }
  }
}

</script>
